import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { InvoliGoogleMapComponent } from '../involi-google-map/involi-google-map.component';

@Component({
    selector: 'location-search',
    templateUrl: 'location-search.component.html',
    styleUrls: ['location-search.component.scss'],
    standalone: false
})
export class LocationSearchComponent implements AfterViewInit
{
    @ViewChild('input') inputRef!: ElementRef;

    constructor(private mapComponent: InvoliGoogleMapComponent)
    {

    }

    ngAfterViewInit()
    {
        const options = {
            fields: ['geometry'],
            strictBounds: false
        };

        const autocomplete = new google.maps.places.Autocomplete(this.inputRef.nativeElement, options);
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            if(!place.geometry)
                return;
            if(place.geometry.location)
                this.mapComponent.googleMap?.panTo(place.geometry.location);
            if(place.geometry.viewport)
                this.mapComponent.googleMap?.fitBounds(place.geometry.viewport);
        });
    }
}