import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, of, catchError, tap, map, distinctUntilChanged, filter } from 'rxjs';
import { KeycloakEvent, KeycloakEventLegacy, KeycloakEventType, KeycloakEventTypeLegacy, KeycloakService } from 'keycloak-angular';
import { ConfigurationService } from '../configuration';
import { InvoliAuthConfig } from './auth-config';
import { UserEntity } from '@involi/api-shared';
import { UserApiService } from '../user';

interface IdpTokenResponse
{
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    id_token:string;
    session_state: string;
    scope: string;
    accessTokenExpiration:number;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService
{
    private readonly REFRESH_TOKEN = 'refresh_token';
    private readonly ACCESS_TOKEN = 'access_token';

    private http: HttpClient;
    private configuration?: InvoliAuthConfig;
    private authenticated$ = new BehaviorSubject<boolean>(false);
    private currentUser$ = new BehaviorSubject<UserEntity | undefined>(undefined);

    constructor(private configurationService: ConfigurationService,
                private userApi: UserApiService,
                private keycloak: KeycloakService,
                httpBackend: HttpBackend)
    {
        this.http = new HttpClient(httpBackend);

        this.keycloak.keycloakEvents$.subscribe((event: KeycloakEventLegacy) => {
            switch(event.type)
            {
                case KeycloakEventTypeLegacy.OnAuthSuccess:
                case KeycloakEventTypeLegacy.OnAuthRefreshSuccess:
                    this.authenticated$.next(true);
                    localStorage.setItem(this.REFRESH_TOKEN, this.keycloak.getKeycloakInstance().refreshToken!);
                    localStorage.setItem(this.ACCESS_TOKEN, this.keycloak.getKeycloakInstance().token!);
                    break;
                case KeycloakEventTypeLegacy.OnAuthError:
                case KeycloakEventTypeLegacy.OnAuthRefreshError:
                case KeycloakEventTypeLegacy.OnAuthLogout:
                    this.authenticated$.next(false);
                    break;
            }
        });
    }

    async init(): Promise<boolean>
    {
        if(!this.configurationService.configuration)
            throw new Error('[AuthService] trying to init without configuration');
        this.configuration = this.configurationService.configuration.involiAuthConfig;
        let refreshToken: string | undefined = localStorage.getItem(this.REFRESH_TOKEN) ?? undefined;
        let accessToken: string | undefined = localStorage.getItem(this.ACCESS_TOKEN) ?? undefined;
        if(!refreshToken || !accessToken)
        {
            refreshToken = undefined;
            accessToken = undefined;
        }

        const isAuthenticated: boolean = await this.keycloak.init({
            config: {
                url: this.configuration.baseIssuer,
                realm: this.configuration.currentRealm,
                clientId: this.configuration.clientId
            },
            initOptions: {
                pkceMethod: 'S256',
                checkLoginIframe: false,
                refreshToken: refreshToken,
                token: accessToken
            },
            bearerExcludedUrls: [this.logoutUrl()]
        }).catch((a) => {
            console.log('init error', a);
            return false;
        });

        if(!isAuthenticated)
            return isAuthenticated;

        const user: UserEntity | null = await firstValueFrom(this.userApi.getCurrentUser().pipe(
            tap(console.log),
            catchError((error: HttpErrorResponse) => { console.log(error); return of(null); })
        ));

        if(user)
            this.currentUser$.next(user);

        this.authenticated$.next(true);
        this.monitorSessionLogout();

        return true;
    }

    login(state?: RouterStateSnapshot): Promise<void>
    {
        let redirectUri = window.location.origin;
        if(state) redirectUri += state.url;
        return this.keycloak.login({
            redirectUri,
            scope: 'offline_access'
        });
    }

    loginIdp(idp: string, redirectUrl: string): Promise<void>
    {
        let redirectUri = window.location.origin;
        redirectUri += redirectUrl;
        return this.keycloak.login({
            redirectUri,
            scope: 'offline_access',
            idpHint: idp,
            maxAge: 1
        });
    }

    getIdpToken(idp: string): Observable<string>
    {
        return this.http.get<IdpTokenResponse>(
            `${this.configuration!.baseIssuer}/realms/${this.configuration!.currentRealm}/broker/${idp}/token`,
            { headers: new HttpHeaders().set('authorization', `bearer ${this.getJwtToken()}`) }
        ).pipe(
            map((response: IdpTokenResponse) => response.access_token)
        );
    }

    isAuthenticated(): Observable<boolean>
    {
        return this.authenticated$.asObservable().pipe(distinctUntilChanged());
    }

    getJwtToken(): string | undefined
    {
        return this.keycloak.getKeycloakInstance().token
    }

    async getValidJwtToken(): Promise<string | undefined>
    {
        await this.keycloak.updateToken();
        return this.getJwtToken();
    }

    hasValidAccess(): boolean
    {
        return this.keycloak.isLoggedIn();
    }

    hasRole(role: string): boolean
    {
        return this.keycloak.getUserRoles().includes(role);
    }

    getSubject(): string | undefined
    {
        return this.keycloak.getKeycloakInstance().subject;
    }

    getRemoteSubject(): string
    {
        return this.keycloak.getKeycloakInstance().tokenParsed?.remoteSub;
    }

    getCurrentUser(): Observable<UserEntity>
    {
        return this.currentUser$.asObservable().pipe(
            filter(Boolean)
        );
    }

    getCurrentUserValue(): UserEntity | undefined
    {
        return this.currentUser$.value;
    }

    setCurrentUser(user: UserEntity)
    {
        this.currentUser$.next(user);
    }

    logout()
    {
        if(!this.configuration)
            return;

        localStorage.removeItem(this.REFRESH_TOKEN);
        localStorage.removeItem(this.ACCESS_TOKEN);

        if(!this.authenticated$.value)
        {
            document.location.href = window.location.protocol + '//' + window.location.host;
            return;
        }

        const request = new HttpParams()
            .set('client_id', this.configuration.clientId)
            .set('refresh_token', this.keycloak.getKeycloakInstance().refreshToken!);
        this.http.post(
            this.logoutUrl(),
            request.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }
        ).subscribe(() => {
            document.location.href = window.location.protocol + '//' + window.location.host;
        });
    }

    private logoutUrl(): string
    {
        return `${this.configuration!.baseIssuer}/realms/${this.configuration!.currentRealm}/protocol/openid-connect/logout`;
    }

    private monitorSessionLogout()
    {
        window.addEventListener('storage', (event: StorageEvent) => {
            if(event.key == this.REFRESH_TOKEN && !event.newValue)
                this.keycloak.clearToken();
        });
    }

    accountUrl()
    {
        return this.configuration?.accountUrl ?? `${this.configuration!.baseIssuer}/realms/${this.configuration!.currentRealm}/account`;
    }
}