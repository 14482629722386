import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class NotManagerGuard extends KeycloakAuthGuard
{
    constructor(keycloak: KeycloakService,
                protected router: Router)
    {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return !this.roles.includes('involi:manager');
    }
}