import { Type } from 'class-transformer';
import { IsLatitude, IsLongitude, IsNumber, IsOptional, ValidateNested } from 'class-validator';

export class CoverageBoundingBox
{
    @IsLatitude()
    southLatitude!: number;

    @IsLongitude()
    westLongitude!: number;

    @IsLatitude()
    northLatitude!: number;

    @IsLongitude()
    eastLongitude!: number;
}

export class GetCoverageDto
{
    @ValidateNested()
    @Type(() => CoverageBoundingBox)
    boundingBox!: CoverageBoundingBox;

    @IsNumber()
    @IsOptional()
    altitude?: number;
}

export class GetReceiverCoverageDto
{
    @IsNumber()
    @IsOptional()
    altitude?: number;
}