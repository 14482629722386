import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { DataOverlay, LayerLevel } from '../data/data-overlay';

export class CoverageRenderer
{
    private coverageLayerId: string;

    private lastDataOverlay?: DataOverlay;
    private lastData?: string[];

    constructor(id: string)
    {
        this.coverageLayerId = `${id}-coverage`;
    }

    render(dataOverlay: DataOverlay, cellIds: string[], enabledColor: boolean = true)
    {
        this.lastDataOverlay = dataOverlay;
        this.lastData = cellIds;

        const coverageLayer = new H3HexagonLayer({
            id: this.coverageLayerId,
            data: cellIds,
            getHexagon: (id: string) => id,
            getFillColor: enabledColor ? [0, 150, 0] : [100, 100, 100],
            highPrecision: false,
            coverage: 1.1,
            opacity: 0.2,
            parameters: {
                blendAlphaOperation: 'max'
            }
        });

        dataOverlay.setLayer(coverageLayer, LayerLevel.Background);
    }

    rerender()
    {
        if(this.lastDataOverlay && this.lastData)
            this.render(this.lastDataOverlay, this.lastData);
    }

    clear(dataOverlay: DataOverlay)
    {
        dataOverlay.removeLayer(this.coverageLayerId);
    }
}
