import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapManagerService } from '../../services/map-manager.service';
import { LatLng } from '../../map';

export interface ClickInfo
{
    object?: any;
    latitude?: number;
    longitude?: number;
}

@Component({
    selector: 'involi-google-map',
    templateUrl: './involi-google-map.component.html',
    styleUrls: ['./involi-google-map.component.scss'],
    standalone: false
})
export class InvoliGoogleMapComponent
{
    @Input() width: string = '500px';
    @Input() height: string = '500px';
    @ViewChild('map') map!: GoogleMap;
    options: google.maps.MapOptions;

    constructor(private mapManager: MapManagerService)
    {
        const noPOI: google.maps.MapTypeStyle = {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
        };

        this.options = {
            backgroundColor: '#110e1b',
            zoomControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            zoom: 8,
            styles: [noPOI],
            isFractionalZoomEnabled: true
        };
    }

    get googleMap(): google.maps.Map | undefined
    {
        return this.map?.googleMap;
    }

    get data(): google.maps.Data | undefined
    {
        return this.map?.data;
    }

    panTo(latitude: number, longitude: number)
    {
        this.mapManager.panTo(latitude, longitude);
    }

    panToIfNotVisible(latitude: number, longitude: number)
    {
        this.mapManager.panToIfNotVisible(latitude, longitude);
    }

    panToPoints(points: LatLng[])
    {
        this.mapManager.panToPoints(points);
    }
}
