import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AdminGuard, NotManagerGuard } from '@involi/api-client';
import { AdminPlatformComponent } from './admin-platform/admin-platform.component';

const routes: Routes = [
    { path: '', component: AdminPlatformComponent, canActivate: [AuthGuard], children: [
        { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [AdminGuard, NotManagerGuard] },
        { path: 'data', loadChildren: () => import('./data/data.module').then(m => m.DataModule), canActivate: [AdminGuard, NotManagerGuard]  },
        { path: 'entity', loadChildren: () => import('./entity/entity.module').then(m => m.EntityModule), canActivate: [AdminGuard, NotManagerGuard]  },
        { path: 'api', loadChildren: () => import('./api/api.module').then(m => m.ApiModule), canActivate: [AdminGuard, NotManagerGuard]  },
        { path: 'mct', loadChildren: () => import('./mct/mct.module').then(m => m.MctModule), canActivate: [AdminGuard, NotManagerGuard]  },
        { path: 'tools', loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule), canActivate: [AdminGuard, NotManagerGuard]  },
        { path: 'organization-management', loadChildren: () => import('./organization-management/organization-management.module').then(m => m.OrganizationManagementModule), canActivate: [AdminGuard]  }
    ]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
