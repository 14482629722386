import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, debounceTime, distinctUntilChanged, exhaustMap, map, of, switchMap, tap, timer, withLatestFrom } from 'rxjs';
import { CoverageApiService } from '@involi/api-client';
import { DataOverlay } from '../data/data-overlay';
import { CoverageRenderer } from './coverage.renderer';
import { Bounds } from '../map/map';
import { MapManagerService } from '../map/services/map-manager.service';
import { CoverageBoundingBox, PermissionReceiverCoverage, ReceiverDetail } from '@involi/api-shared';
import { PermissionsService } from '../permissions';

@Injectable()
export class CoverageService
{
    private renderer: CoverageRenderer;
    private dataOverlay?: DataOverlay;
    private altitude$ = new BehaviorSubject<number | undefined>(undefined);
    private enabled$ = new BehaviorSubject<boolean>(false);
    private receiver$ = new BehaviorSubject<ReceiverDetail | undefined>(undefined);

    constructor(coverageApi: CoverageApiService,
                mapManager: MapManagerService,
                permissionsService: PermissionsService)
    {
        this.renderer = new CoverageRenderer('coverage');

        this.enabled$.pipe(
            tap((enabled: boolean) => {
                if(!enabled && this.dataOverlay)
                    this.renderer.clear(this.dataOverlay);
            }),
            switchMap((enabled: boolean) => {
                return combineLatest([
                    this.altitude$,
                    mapManager.getMainBoundsUpdates(),
                    this.receiver$.pipe(distinctUntilChanged()),
                    permissionsService.hasPermission(PermissionReceiverCoverage)
                ]).pipe(
                    map(([altitude, bounds, receiver, receiverCoveragePermission]): [boolean, number | undefined, Bounds, ReceiverDetail | undefined, boolean] => [enabled, altitude, bounds, receiver, receiverCoveragePermission])
                );
            }),
            debounceTime(1000),
            switchMap(([enabled, altitude, bounds, receiver, receiverCoveragePermission]) =>
                enabled
                ? timer(0, 5000).pipe(map((): [number | undefined, Bounds, ReceiverDetail | undefined, boolean] => [altitude, bounds, receiver, receiverCoveragePermission]))
                : EMPTY
            ),
            exhaustMap(([altitude, bounds, receiver, receiverCoveragePermission]) => {
                const bb: CoverageBoundingBox = {
                    southLatitude: bounds.sw.latitude,
                    northLatitude: bounds.ne.latitude,
                    westLongitude: bounds.sw.longitude,
                    eastLongitude: bounds.ne.longitude
                };
                const request$ = (receiver && receiverCoveragePermission
                    ? coverageApi.getReceiverCoverageCellIds(receiver.primary_id, altitude)
                    : coverageApi.getCoverageCellIds(bb, altitude));
                return request$.pipe(
                    map((cellIds: string[]): [string[], boolean] => [cellIds, !receiverCoveragePermission || (receiver?.is_online ?? true)])
                );
            }),
            catchError((error: unknown) => {
                console.log(error);
                return of([]);
            })
        ).subscribe(([cellIds, enabledColor]) => {
            if(this.dataOverlay && this.enabled$.getValue())
                this.renderer.render(this.dataOverlay, cellIds, enabledColor);
        });
    }

    init(dataOverlay: DataOverlay)
    {
        this.dataOverlay = dataOverlay;
    }

    isEnabled(): boolean
    {
        return this.enabled$.getValue();
    }

    watchEnabledStatus(): Observable<boolean>
    {
        return this.enabled$.asObservable();
    }

    enable(enabled: boolean)
    {
        this.enabled$.next(enabled);
    }

    getCurrentAltitude(): number | undefined
    {
        return this.altitude$.getValue();
    }

    setAltitude(altitude?: number)
    {
        this.altitude$.next(altitude);
    }

    setReceiver(receiver: ReceiverDetail | undefined)
    {
        this.receiver$.next(receiver);
    }

    resetReceiver()
    {
        this.receiver$.next(undefined);
    }
}