import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoverageBoundingBox, CoverageGeoJSON, GetCoverageDto, GetReceiverCoverageDto } from '@involi/api-shared';
import { ApiClient } from '../core/api-client';
import { API_CONFIG, ApiConfig } from '../api';
import { ApiStatusService } from '../core/api-status.service';

@Injectable({
    providedIn: 'root'
})
export class CoverageApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Coverage');
        this.apiUrl = `${config.involiApiUrl}/coverage`;
    }

    getCoverageGeoJson(boundingBox: CoverageBoundingBox): Observable<CoverageGeoJSON>
    {
        const body: GetCoverageDto = { boundingBox: boundingBox };
        return this.post<CoverageGeoJSON>(`${this.apiUrl}/geojson`, body);
    }

    getCoverageCellIds(boundingBox: CoverageBoundingBox, altitude?: number): Observable<string[]>
    {
        const body: GetCoverageDto = {
            boundingBox,
            altitude
        };
        return this.post<string[]>(`${this.apiUrl}/ids`, body);
    }

    getReceiverCoverageCellIds(receiverId: string, altitude?: number): Observable<string[]>
    {
        const body: GetReceiverCoverageDto = { altitude };
        return this.post<string[]>(`${this.apiUrl}/receiver/${receiverId}/ids`, body);
    }
}
