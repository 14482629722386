import { Component } from '@angular/core';

@Component({
    selector: 'card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
    standalone: false
})
export class CardComponent
{
    
}