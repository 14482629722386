import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable } from 'rxjs';
import { AddPermissionDto, Entity, IsPermissionInitDto, PermissionGroup, WhoHasPermissionDto } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class PermissionApiService
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
    private http: HttpClient)
    {
        this.apiUrl = `${config.involiApiUrl}/permission`;
    }

    getAllPermissions(): Observable<string[]>
    {
        return this.http.get<string[]>(`${this.apiUrl}/all`);
    }

    getUserPermissions(): Observable<string[]>
    {
        return this.http.get<string[]>(this.apiUrl);
    }

    initDatabasePermissions(): Observable<void>
    {
        return this.http.get<void>(`${this.apiUrl}/init-database`);
    }

    isDatabaseInit(): Observable<IsPermissionInitDto>
    {
        return this.http.get<IsPermissionInitDto>(`${this.apiUrl}/is-database-init`);
    }

    whoHasPermission(permission: string): Observable<WhoHasPermissionDto>
    {
        return this.http.get<WhoHasPermissionDto>(`${this.apiUrl}/${permission}/entity`);
    }

    removePermission(permission: string, entityType: string, entityId: string): Observable<void>
    {
        return this.http.delete<void>(`${this.apiUrl}/${permission}/entity/${entityType}/${entityId}`);
    }

    getEntityPermissions(entityType: string, entityId: string): Observable<string[]>
    {
        return this.http.get<string[]>(`${this.apiUrl}/entity/${entityType}/${entityId}`);
    }

    getInheritedEntityPermissions(entityType: string, entityId: string): Observable<PermissionGroup[]>
    {
        return this.http.get<PermissionGroup[]>(`${this.apiUrl}/inherited/entity/${entityType}/${entityId}`);
    }

    addPermission(permission: string, entityType: string, entityId: string): Observable<void>
    {
        const request: AddPermissionDto = { entityType, entityId };
        return this.http.post<void>(`${this.apiUrl}/${permission}/entity`, request);
    }
}